import { Controller } from "@hotwired/stimulus";

// Modal controller
export default class extends Controller {
  connect() {
    this.orderAiProcessingDataElement = document.getElementById("order-ai-processing-data");
    this.loadingOverlay = document.getElementById("loading-overlay");
    this.aiProcessing = this.orderAiProcessingDataElement.getAttribute("data-order-ai-processing") === 'true';
    this.orderId = this.orderAiProcessingDataElement.getAttribute("data-order-id");

    if (this.aiProcessing) {
      this.showLoadingOverlay();
      this.pollAiProcessingStatus();
    }
  }

  pollAiProcessingStatus() {
    this.pollingInterval = setInterval(() => {
      fetch(`/orders/${this.orderId}/ai_processing_status`)
        .then(response => response.json())
        .then(data => {
          if (!data.ai_processing) {
            this.hideLoadingOverlay();
            clearInterval(this.pollingInterval);
          }
        })
        .catch(error => console.error('Error fetching AI processing status:', error));
    }, 5000);
  }

  showLoadingOverlay() {
    this.loadingOverlay.style.display = 'block';
  }

  hideLoadingOverlay() {
    window.location.reload();
  }
}
