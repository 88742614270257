import { Controller } from "@hotwired/stimulus"
import { Device } from "@twilio/voice-sdk";
import {show, hide} from "../../utils"

// Connects to data-controller="calling--twilio-outbound-calls"
export default class extends Controller {

  connect() {
    this.callButton = document.getElementById('call-button');
    this.endCallButton = document.getElementById('end-call-button');
    this.muteButton = document.getElementById('mute-button');
    let phoneNumberDataElement = document.getElementById("phone-number");
    this.phoneNumber = phoneNumberDataElement.getAttribute("data-phone-number");
    let userIdDataElement = document.getElementById("user-id");
    this.userId = userIdDataElement.getAttribute("data-user-id");
    let orderIdDataElement = document.getElementById("order-id");
    this.orderId = orderIdDataElement.getAttribute("data-order-id");
    this.virtualPhone = document.getElementById('virtual-phone');
    this.dtmfKeys = document.querySelectorAll('.dtmf-key');
    this.dtmfInput = document.getElementById('dtmf-input');
    this.callStatusIndicator = document.getElementById('call-status-div');
    this.device = null;
    this.call = null;

    this.callButton.addEventListener("click", this.startCall.bind(this));
    this.endCallButton.addEventListener("click", this.endCall.bind(this));
    this.muteButton.addEventListener("click", this.toggleMute.bind(this));
    this.dtmfKeys.forEach(button => {
      button.addEventListener('click', this.addListenerToDialButton.bind(this));
    });
    document.addEventListener('modal:hidden', this.handleHidden.bind(this))
  }

  disconnect() {
    if (this.device) {
      this.device?.disconnectAll();
      this.device?.destroy();
    }
  }

  handleHidden() {
    if (this.device) {
      this.device?.disconnectAll();
      this.device?.destroy();
    }
  }

  addListenerToDialButton(event) {
    const tone = event.target.getAttribute('data-tone');
    if (this.call) {
      this.call.sendDigits(tone);
    }
  }

  toggleMute() {
    if (this.call == null) return

    if (this.call.isMuted()) {
      this.unmuteCall();
    } else {
      this.muteCall();
    }
  }

  muteCall() {
    this.call.mute(true);
    this.muteButton.innerText = "Unmute"
  }

  unmuteCall() {
    this.call.mute(false);
    this.muteButton.innerText = "Mute"
  }

  initializeDevice() {
    let twilioTokenDataElement = document.getElementById("twilio-token");
    let twilioToken = twilioTokenDataElement.getAttribute("data-twilio-token");
    this.device = new Device(twilioToken, { enableRingingState: true, });
  }

  startCall() {
    this.createPhoneCallObjectAndStartCall(this.userId, this.orderId, this.phoneNumber);
  }

  createPhoneCallObjectAndStartCall(userId, orderId, phoneNumber) {

    function appendPhoneCallIdToFormField(uuid) {
      let phoneCallIds = document.getElementById("phone_call_ids_str");
      let currentValue = phoneCallIds.value ? JSON.parse(phoneCallIds.value) : [];

      // Append the new UUID to the array
      currentValue.push(uuid);

      // Update the hidden field with the new list of UUIDs (comma-separated)
      phoneCallIds.value = JSON.stringify(currentValue);
    }

    fetch('/phone_calls', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        phone_call: {
          user_id: userId,
          order_id: orderId,
          phone_number: phoneNumber
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.id) {
        appendPhoneCallIdToFormField(data.id);
        this.initializePhoneCall(data.id);
      }
    })
    .catch(error => console.error("Error:", error));
  }

  initializePhoneCall(phoneCallId) {
    // hide call button
    hide(this.callButton)
    // show end button
    show(this.endCallButton)
    // show dial pad
    show(this.virtualPhone)
    // show mute button
    show(this.muteButton)
    // start call
    const params = {
      To: this.phoneNumber,
      phone_call_id: phoneCallId
    };
    this.callStatusIndicator.innerText = "Connecting Call..."
    if (this.device == null) {
      this.initializeDevice();
    }

    let callPromise = this.device.connect({ params: params });
    callPromise.then((callObj) => {
      this.call = callObj;
      this.call.on("accept", () => {
        this.callStatusIndicator.innerText = null;
      });
      this.call.on("disconnect", () => {
        this.endCall();
      });
      this.call.on("error", () => {
        this.endCall();
      });
      this.call.on("cancel", () => {
        this.endCall();
      });
    })
  }

  endCall() {
    // show call button
    show(this.callButton)
    // hide end button
    hide(this.endCallButton)
    // hide dial pad
    hide(this.virtualPhone)
    // hide mute button
    hide(this.muteButton)
    this.unmuteCall();
    // end the call and cleanup the device
    this.callStatusIndicator.innerText = null
    if (this.device) {
      this.device?.disconnectAll();
      this.device?.destroy();
      this.device = null;
    }
  }
}
