import { Controller } from "@hotwired/stimulus";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import cytoscapePopper from "cytoscape-popper";
import { createPopper } from "@popperjs/core";

cytoscape.use(cytoscapePopper);

export default class extends Controller {
  connect() {
    this.graphsContainerTitle = document.getElementById("graphs-container-title");
    this.graphsContainer = document.getElementById("graphs-container");
    // this.toggleIcon = document.getElementById("toggle-icon");

    this.graphsContainerTitle.addEventListener("click", () => {
      this.toggleGraph();
    });

    this.currentStepCountsElement = document.getElementById("current-step-counts-element");
    this.currentStepCounts = JSON.parse(this.currentStepCountsElement.getAttribute("data-current-step-counts"));
    this.graphDataElement = document.getElementById("transition-graph-data-element");
    this.graphData = JSON.parse(this.graphDataElement.getAttribute("data-transition-graph-data"));
    this.graphTarget = document.getElementById("transition-graph");
    this.renderTransitionGraph(this.graphData, this.currentStepCounts, this.graphTarget);

    this.graphsContainer.hidden = false;
  }

  toggleGraph() {
    let toggleIcon = document.getElementById("toggle-icon");
    if (this.graphsContainer.hidden) {
      this.graphsContainer.hidden = false;
      this.updateToggleIcon(toggleIcon, "down");
    } else {
      this.graphsContainer.hidden = true;
      this.updateToggleIcon(toggleIcon, "up");
    }
  }

  updateToggleIcon(toggleIcon, direction) {
    if (toggleIcon) {
      if (direction === "up") {
        // Update the SVG to show the "up" icon
        toggleIcon.setAttribute("data-icon", "chevron-up");
        // You might need to update other attributes or classes depending on your SVG
      } else {
        // Update the SVG to show the "down" icon
        toggleIcon.setAttribute("data-icon", "chevron-down");
        // You might need to update other attributes or classes depending on your SVG
      }
    }
  }

  renderTransitionGraph(graphData, currentStepCounts, graphTarget) {
    // Extract unique nodes from the graph data to use as parent nodes
    const parentNodes = Array.from(new Set(graphData.flatMap((data) => [data.from, data.to]))).map((node) => {
      const stepCount = currentStepCounts[node]?.count ?? 0;
      const singleFamily = currentStepCounts[node]?.single_family ?? 0;
      const multiFamily = currentStepCounts[node]?.multi_family ?? 0;
      const independentLandlord = currentStepCounts[node]?.independent_landlord ?? 0;
      const relatedLandlord = currentStepCounts[node]?.related_landlord ?? 0;

      return {
        data: {
          id: node,
          label: `${node} (${stepCount})`,
          stepCount,
          singleFamily,
          multiFamily,
          independentLandlord,
          relatedLandlord,
        },
      };
    });

    // Create edge elements
    const edgeElements = graphData.map((data) => ({
      data: {
        id: `${data.from}-${data.to}`,
        source: data.from,
        target: data.to,
        label: data.count,
        count: data.count,
      },
    }));

    cytoscape.use(dagre);
    cytoscape.use(cytoscapePopper(createPopper));

    // Initialize Cytoscape
    const cy = cytoscape({
      container: graphTarget,
      elements: [...parentNodes, ...edgeElements],
      style: [
        // the stylesheet for the graph
        {
          selector: "node",
          style: {
            "background-color": (ele) => {
              const count = this.currentStepCounts[ele.data("id")]?.count ?? 0;
              return this.getNodeColor(count);
            },
            label: (ele) => {
              return `${ele.data("label")}`;
            },
            width: "label",
            height: "label",
            padding: "20px",
            shape: "round-rectangle",
            "text-valign": "center",
            "text-halign": "center",
            "text-wrap": "wrap",
            "text-max-width": 300,
          },
        },
        {
          selector: "edge",
          style: {
            width: 4,
            "line-color": (ele) => this.getEdgeColor(ele.data("count")),
            "target-arrow-color": (ele) => this.getEdgeColor(ele.data("count")),
            "target-arrow-shape": "triangle",
            "target-arrow-fill": "filled",
            "arrow-scale": 2,
            "curve-style": "bezier",
            label: "data(label)",
            "text-rotation": "none",
            "text-margin-y": -10,
            "text-transform": "rotate(-90deg)",
          },
        },
      ],
      layout: {
        name: "dagre",
        directed: true,
        padding: 10,
      },
      minZoom: 0.3,
    });

    cy.on("tap", "node", (event) => {
      const node = event.target;
      const content = `
        Single: ${node.data("singleFamily")} vs Multi: ${node.data("multiFamily")}<br>
        Independent: ${node.data("independentLandlord")} vs Related: ${node.data("relatedLandlord")}
      `;
      this.showTooltip(node, content);
    });
  }

  showTooltip(node, content) {
    const ref = node.popperRef(); // Create a Popper reference from the node

    const dummyDomEle = document.createElement("div");
    document.body.appendChild(dummyDomEle);

    const tooltip = tippy(dummyDomEle, {
      content: content,
      trigger: "manual",
      placement: "bottom",
      allowHTML: true,
      getReferenceClientRect: ref.getBoundingClientRect,
      onMount(instance) {
        instance.popperInstance.update();
      },
    });

    tooltip.show();

    // Clean up the dummy element after showing the tooltip
    setTimeout(() => {
      tooltip.destroy();
      document.body.removeChild(dummyDomEle);
    }, 5000); // Adjust the timeout as needed
  }

  getEdgeColor(count) {
    const counts = this.graphData.map((data) => data.count);
    const startColor = { r: 211, g: 211, b: 211 }; // #d3d3d3 (light gray)
    const endColor = { r: 102, g: 102, b: 102 }; // #666666 (darker gray)

    return this.interpolateColor(count, counts, startColor, endColor);
  }

  getNodeColor(count) {
    const counts = Object.values(this.currentStepCounts).map((step) => step.count);
    const startColor = { r: 220, g: 224, b: 255 }; // #dce0ff (even lighter purple)
    const endColor = { r: 150, g: 157, b: 250 }; // #969dfa (darker purple)

    return this.interpolateColor(count, counts, startColor, endColor);
  }

  interpolateColor(count, counts, startColor, endColor) {
    const minCount = Math.min(...counts);
    const maxCount = Math.max(...counts);

    // Calculate the percentage (0 to 1) based on count
    const percentage = Math.min(Math.max((count - minCount) / (maxCount - minCount), 0), 1);

    // Interpolate between the colors
    const r = Math.round(startColor.r + (endColor.r - startColor.r) * percentage);
    const g = Math.round(startColor.g + (endColor.g - startColor.g) * percentage);
    const b = Math.round(startColor.b + (endColor.b - startColor.b) * percentage);

    return `rgb(${r}, ${g}, ${b})`;
  }
}
